import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import useDigitalService from "../../../Hooks/useDigitalService";
import Developments from "../Developments/Developments";
import "./Development.css";

const Development = () => {
  const [develop, setDevelop] = useState([]);

  const [service] = useDigitalService();

  useEffect(() => {
    fetch("https://digitalbackend.techsistltd.com/business_service_details/")
      .then((res) => res.json())
      .then((data) => setDevelop(data.data.service_description));
  }, []);

  return (
    <div className="container-fluid  card-container py-5">
      <div className="container ">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="card-container-header-text py-4">{service.title} </h2>
          <hr className="horizontal-line mt-3" />
        </div>

        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
          {develop?.map((develops) => (
            <Developments key={develops.id} develops={develops}></Developments>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Development;
